import { Sprite, utils } from 'pixi.js'

/**
 * An UI button object
 *
 * @class
 * @extends PIXI.utils.EventEmitter
 */

export class ButtonTexture extends utils.EventEmitter {
    constructor (options) {
        super (options);
        this._options = options;
        const _btn = new Sprite (options.textureButton);

        const ctx = this;
        _btn._btnMode = true;
        _btn.anchor.set (options.anchor ? options.anchor : 0.5);
        _btn.position.x = options.x;
        _btn.position.y = options.y;
        _btn.interactive = true;

        function onButtonUp () {
            this.isdown = false;
            if (this.isOver) {
                ctx.emit ("BUTTONCONFIRM");
                this.texture = ctx._options.textureButtonOver;
            } else {
                ctx.emit ("BUTTONUP");
                this.texture = ctx._options.textureButton;
            }
           // window.console.log ("button up")
        }


        function onButtonDown () {
            this.isdown = true;
            ctx.emit ("BUTTONDOWN");
            this.texture = ctx._options.textureButtonDown;
            this.alpha = 1;
           // window.console.log ("button down")
        }


        function onButtonOver () {
            this.isOver = true;
            ctx.emit ("BUTTONOVER");
            if (this.isdown) {
                return;
            }
            this.texture = ctx._options.textureButtonOver;
        }

        function onButtonOut () {
            this.isOver = false;
            ctx.emit ("BUTTONOUT");
            if (this.isdown) {
                return;
            }
            this.texture = ctx._options.textureButton;
        }

        _btn
        // set the mousedown and touchstart callback...
            .on ('mousedown', onButtonDown)
            .on ('touchstart', onButtonDown)
            // set the mouseup and touchend callback...
            .on ('mouseup', onButtonUp)
            .on ('touchend', onButtonUp)
            .on ('mouseupoutside', onButtonUp)
            .on ('touchendoutside', onButtonUp)
            // set the mouseover callback...
            .on ('mouseover', onButtonOver)
            // set the mouseout callback...
            .on ('mouseout', onButtonOut)
        //  .on ('tap', onButtonDown)
        //  .on ('click', onButtonDown)

        this.button = _btn;
    }

    getButtonFace () {
        return this.button;
    }


}
